// BG COLORS
$bg-yellow: #FDB415 !default;

// TEXT COLOR 
$white: #ffffff;
$blue: #0B1339;
$prime: #FDB415;

// FLEX 
.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
}

.grow-1 {
    flex-grow: 1;
}

// ALIGN 
.f-align-start {
    align-items: flex-start;
}

.f-align-center {
    align-items: center;
}

.f-align-end {
    align-items: flex-end;
}

// JUSTIFY 
.justify-start {
    justify-content: flex-start
}

.flex_end{
    display: flex;
    justify-content: flex-end;
}

.justify-center{
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-space-between {
    justify-content: space-between;
}

.btn {
    background: $bg-yellow;
    color: $white;
    outline: none;
    border: 0;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600 !important;
    // text-transform: uppercase;
    // font-weight: bold;
    
    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            background: darken($bg-yellow, 8%);
            color: $blue;
        }
    }
}


// COLOR 
.text-prime {
    color: $prime;
}

.color-link {
    color: $prime;
    outline: none;
    cursor: pointer;

    &:hover {
        color: darken($prime, 8%);
    }
}

// TEXT ALIGNMENT
.text-center {
    text-align: center;
}

// PADDING 
.padding-10-0 {
    padding: 10px 0;
}

.padding-20-0 {
    padding: 20px 0;
}

.padding-4-0 {
    padding: 4px 0;
}

.padding-20-17{
    padding: 20px 17px !important;
}
.padding-16-5{
    padding: 16px 5px !important;
}
.padding-5-5{
    padding: 5px 5px !important;
}

.padding-bottom-10{
    padding-bottom: 10px;
}

// MARGIN 
.margin-t-5px {
    margin-top: 5px;
}

.margin-t-10px {
    margin-top: 10px;
}

.margin-t-20 {
    margin-top: 20px;
}

// FONT SIZE 
.font-10{
    font-size: 10px;
}

.font-14 {
    font-size: 14px;
}

.font-20{
    font-size: 20px;
}

.font-16 {
    font-size: 16px;
}

.ant-switch-checked{
    background-color: #fdb415 !important;
}

//btn
.ant-btn{
    font-weight: 600 !important;
}

@media  screen and (max-width: 900px) and (orientation: landscape) {
    // MODAL
    .ant-modal{
        top: calc(100% - 370px) !important;
        bottom: auto !important;
    }

    .form_modal{
        top: unset !important;
        bottom: unset !important;
    }

    .merchant-scrol-form{
        .section-view {
            height: unset !important;
        }
        
    }
}


@media (max-width: 550px) {
    .merchant-sroll-overflow{
        overflow-x: scroll; 
        overflow-y:scroll;   
        height: calc(100vh - 100px); 
    }
    .merchant-sroll-overflow-tab{
        overflow-x: scroll; 
        overflow-y:scroll; 
        height: calc(100vh - 170px);    
    }
    .merchant-scrol-form{
        width: 150vh;
    }

    .ant-table-body{
        overflow-y: hidden !important;
        max-height: unset !important;
    }

    .ant-table-header{
        overflow: unset !important;
    }

    .section-view{
        height: unset !important;
        min-height: calc(100vh - 56px) !important;
    }

    
}
