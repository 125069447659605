.field-label{
    display: block;
    padding-bottom: 4px;
    // font-weight: 500;

    &.required::before {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}


.form_body {

    .ant-form-item-has-error {
        input{
            border-color: #ff4d4f !important;
        }
    }
}