.notification{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 26px;
   .notification_circle{
        background-color: #FDB415;
        text-align: center;
        height: 45vh;
        width: 45vh;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 26px;
        margin-bottom: 10px;

        .notification_ctr{
            font-weight: bold;
            font-size: 16vh;
        }
   }

   .view_order_btn{
        background-color: #FDB415;
        color: #ffffff;
        padding: 8px 8vh;
        border-radius: 5px;
        margin-top: 15px;
        font-size: 16px;
        cursor: pointer;
   }

    .view_order_btn:hover{
        background-color: #e8a513;
    }
}

.notification_btn{
    display: flex;
    justify-content: center;
    button{
        border-radius: 5px;
        // width: 30vh;
        width: 150px;
    }
}

.sound_btn{
    border: 0px;
    color: #e8e8e8;
    background-color: transparent;
}
