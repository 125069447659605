.about{
    min-height: 75vh;
    
    .about_banner{
        background: url(../img/home_banner.png);
        background-repeat:no-repeat;
        background-position: center;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #0B1339;
        }
    }

    .about_text {
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            width: 500px;
            font-style: normal;
            padding-top: 5vh;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #0B1339;
        }
    }

    @media (max-width: 550px) {
        .about_banner {
            background-size: 140%;
            height: 150px;
            padding-top: 2vh;
        }
    }
    
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after
{
   border-top: 1px solid #FDB415 !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::before{
    border-top: 1px solid #FDB415 !important;
    width: 0% !important;
}
.ant-divider-horizontal.ant-divider-with-text-left::after
{
    border-top: 1px solid #FDB415 !important;
}
