.receivable{
    .receivable_lable{
        font-weight: bold;
        padding-right: 4px;
    }
    .col-flex-end{
        display: flex;
        justify-content: flex-end;
    }
    .receivable_row{
        padding-bottom: 10px;
    }

    .card_info{
        display: flex;
        justify-content: space-between;
        .form-item-center{
            padding: 0 10px;
        }
    }
    .ant-form-item-label{
        width: 135px !important;
    }

    .ant-from-item-btn{
        justify-content: flex-end;
        display: flex;

    }
    .submit_btn{
        width: 80px;
        height: 40px;
    }
}
.amount_right{
    text-align: end !important;
    padding-right: 28vh !important;
}