.ant-layout-content-gray {
    max-height: calc(100vh - 75px);
    overflow-x: hidden;
    overflow-y: auto;
    //min-height: 900px;
    background-color: #e8e8e8 !important;

    .footer {
        padding-top: 35px;
    }
}

.ant-layout-content-gray-order {
    max-height: calc(100vh - 10px);
    overflow-x: auto;
    overflow-y: auto;
    background-color: #e8e8e8 !important;
    padding-bottom: 0px !important;

    .footer {
        padding-top: 35px;
    }
}

.ant-layout-content-white {
    max-height: calc(100vh - 75px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff !important;
}

.ant-page-header-heading-title {
    font-weight: 700 !important;
}
