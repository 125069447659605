.customization-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    
    .ant-table-small .ant-table-thead > tr > th{
        padding-right: 14px !important;
    }

    .customization_btn{
        display: flex;
        width: 230px;
        font-size: 16px;
        justify-content: space-between
    }

    .action_center{
        display: flex;
        justify-content: flex-end;
        span{
            padding-right: 1vh;
        }
    }

    .list{
        padding-top: 6vh;
        width: 100%;

        .ant-checkbox-checked::after {
            border: 1px solid #e79f02;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #e79f02;
            border-color: #e79f02;
        }

        .ant-checkbox-group .ant-row{
            padding-bottom: 8px;
        }
    }

    .modifier-table{
        .ant-table-container table > thead > tr:first-child th:first-child{
            padding-left: 12px;
        }
    }

    .customization-table{
        width: 100%;
        .ant-table-container table > thead > tr:first-child th:first-child {
            padding-left: 14px;
        }

        .td-14{
            padding-left: 14px !important;
        }
    }

    @media (max-width: 550px) {
        .customization-table{
            width: 98%;
        }
    }
}