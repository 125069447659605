.button-text{
    color: #FDB415;
    cursor: pointer;
    &:active{
        color: #FDB415;
    }

    &:hover{
        color: #FDB415;
    }
}

.button-text-primary{
    color: #1890ff;
    cursor: pointer;
    &:active{
        color: #1890ff;
    }

    &:hover{
        color: #1890ff;
    }
}

.button-text-danger{
    color: red;
    cursor: pointer;
    &:active{
        color: red;
    }

    &:hover{
        color: red;
    }
}
// .ant-btn-primary{
//     background-color: #FDB415 !important;
//     border-color: #FDB415 !important;
// }

.ant-btn{
    height: 40px !important;
    font-size: 16px !important;
}

.ant-pagination-item-active a {
    color: #FDB415 !important;
}

.ant-pagination-item-active {
    border-color: #FDB415 !important;
}

.hideFooter .ant-modal-footer{
    display: none;
}

.displayFooter .ant-modal-footer{
    display: block;
}

.order_footer_show{
    display: block !important;
}

.order_footer_hide{
    display: none !important;
}

.ant-btn-primary{
    background: #FDB415 !important;
    border-color: #FDB415 !important;
}