.requiredLabel::after{
    color: #ff4d4f;
    content: '*';
    margin-left: 4px;
}

.required::before{
    color: #ff4d4f;
    content: '* ';
    margin: 4px;
    font-size: 14px;
}

.ant-form-item{
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 10px !important;
}

.ant-form-item-label{
    text-align: unset !important;
}

.ant-modal-title{
    font-weight: bold !important;
}

.capitalize {
    text-transform: capitalize;
}

.flex-end{
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: end;
}
