$prime: #1890ff !default;
.merchant-new-form {
    width: 100%;
    background-color: #ffffff;

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background: #e6f7ff !important
    }
    .section-view {
        box-shadow: unset;
    }
    .merchant-tab {
        //height: 100vh;
        padding: 0 30px;
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #fdb415 !important;
        }
        .ant-tabs-nav .ant-tabs-tab-active {
            color: #fdb415;
        }
        .ant-tabs-ink-bar {
            background-color: #fdb415;
        }
    }
    .merchant-new-header {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        padding: 15px 12px;
        padding-top: 23px;
        border-bottom: 1.5px solid #e8e8e8;
    }

    .inner-tabs {
        margin-top: 23px;
        .ant-tabs-tab {
            padding-left: 12px !important;
        }
    }

    .form-row {
        padding: 15px 12px;
    }
    .form-subtitle {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    form {
        font-size: 18px;
        padding: 0 25px !important;
        display: flex !important;
        flex-direction: column;
        padding: unset !important;

        .form-row {
            .input-label {
                padding-bottom: 10px;
            }
        }

        .ant-select-single .ant-select-selector .ant-select-selection-item {
            line-height: 1.5715;
        }

        .selectTime {
            div {
                height: 5vh !important;
                // background-color: #f8f8f8c7 !important;
                .ant-select-selection-placeholder {
                    padding: 5px 0 !important;
                }
                span {
                    padding: 5px 0 !important;
                }
            }
        }

        .input-number {
            width: 100%;
        }
        input {
            // height: 5vh;
            width: 100% !important;
            height: 42px;
            // background-color: #f8f8f8c7 !important;
        }

        .input-half {
            width: 100%;
            height: 42px;
            // height: 5vh;
            // background-color: #f8f8f8c7 !important;
        }

        .input-whole {
            width: 50%;
            height: 5vh;
            // background-color: #f8f8f8c7 !important;
        }

        .input-col1 {
            width: 48%;
            height: 5vh;
            // background-color: #f8f8f8c7 !important;
        }
        textarea {
            height: 5vh;
            // background-color: #f8f8f8c7 !important;
            margin-right: 25px;
            min-height: 70px !important;
        }

        .col-1 {
            padding-right: 15px;
        }

        .col-2 {
            padding-left: 15px;
        }

        .select_input {
            .ant-select-selector {
                // height: 5vh;
                // background-color: #f8f8f8c7 !important;
                // padding: 4px 10px;
                height: 42px !important;
            }
            .ant-select-selection-search-input {
                height: 35px !important;
            }
        }

        .half_field {
            width: 49%;
        }

        .selectTime {
            .ant-select-selector {
                align-items: center;
                height: 42px !important;
            }
        }

        .select_tags {
            width: 48% !important;
            .ant-select-selector {
                width: 100%;
                min-height: 5vh;
                // background-color: #f8f8f8c7 !important;
                padding: 0px 8px;
            }
        }

        .ant-form-item {
            margin: 1px 0;
        }

        .submit_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 30px;
            margin-bottom: 15px;
            width: 20vh;
            height: 6vh;
            font-size: 18px;
            background-color: #fdb415;
            border-color: #fdb415;
        }

        .ant-select-arrow {
            right: 32px !important;
        }
        .form-btn {
            .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-end;
            }
        }

        .require {
            color: #ff4d4f;
        }

        .ant-select-single .ant-select-selector .ant-select-selection-item {
            display: flex;
            align-items: center;
        }

        .ant-select-single.ant-select-show-arrow
            .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
        }

        @media (max-width: 900px) {
            .form-subtitle {
                font-size: 22px;
            } 
        }
    }
    .ant-picker-input {
        input {
            width: 38vh;
            height: 4vh;
        }
    }
    // .ant-picker {
        // background-color: #f8f8f8c7;
    // }

    .ant-switch-checked {
        background-color: #fdb415;
    }
}

.address_suggestion{
    // padding: 10px;
    // background-color: #FDB415;
    // border-radius: 5px;
    // margin: 10px 0;
    // cursor: pointer;
    
    padding: 10px;
    // margin: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid gray;
}

.first_address_suggestion{
    border-top: 1px solid gray;
}

.address_suggestion:hover {
    background-color: #e8a513;
}

.edit-btn {
    margin-top: 10px;
    color: #ffffff !important;
    background: #fdb415 !important;
}

.csv-btn {
    margin-top: 10px;
    color: #ffffff;
    background: #fdb415;
}

.manage-btn {
    margin-left: 20px;
    color: #ffffff !important;
    padding: 5px 15px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    width: 36vh;
    text-align: center;
    background: #fdb415 !important;
}

@media only screen and (max-width : 900px) {
    .manage-btn{
        width: unset;
    }

    .merchant-new-form{
        min-height: 10vh;
        .inner-tabs{
            // height: calc(100vh - 20px) !important;
            min-height: calc(100vh - 6px) !important;
            // height: calc(100vh - -37px) !important;

        }

        .section-view {
            height: unset !important;
        }

        form .submit_btn{
            // width: 30vh;
            width: 130px;
        }
    }

    .ant-table-body{
        min-height: calc(100vh - 254px) !important;
        // max-height: calc(100vh - 240px) !important
    }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #e8a513 !important;
}

.ant-tabs-tab:hover {
    color: #e8a513 !important;
}

.ant-tabs-ink-bar {
    background-color: #fdb415 !important;
}

.btn_left {
    margin-right: 20px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #888888;
}
