.settings{
    padding-bottom: 15px;
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #FDB415 !important;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
        color: #FDB415;
    }
    .ant-tabs-ink-bar {
        background-color: #FDB415;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #FDB415;
        border-color: #FDB415;
    }

    .ant-tabs-tab {
        margin: 0 45px 0 0 !important;
    }

    .content{
        display: flex;
        flex-direction: column;
    }

}

.save_btn{
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.delete_btn{
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    span{
        background-color: #F04134;
        color: #FFFFFF;
        height: 40px!important;
        font-size: 16px!important;
        display: flex;
        align-items: center;
        padding: 0 15px;
        border-radius: 5px;
        cursor: pointer;
    }
}