.section-view {
    width: 100%;
    background: #fff;
    padding: 12px;
    border-radius: 1px;
    box-shadow: 1px 1px 4px 1px #e7e7e7;

    .date_range{
        width: 100%;
        align-items: center;
        justify-content: flex-end !important;
        display: flex;
        flex-direction: row;
    }
}
