$bg-error-btn: #0A1339;
$bg-success-btn: #FDB415;
$btn-min-width: 150px;

.error-modal,.success-modal {

    .ant-modal-content{
        border-radius: 15px !important;

        .appetit-modal-container{
            flex-direction: column;
            text-align: center;
            #icon-container{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                div {
                    margin-top: -60px;
                    background: white;
                    border-radius: 30px;
                    padding: 5px;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    text-align: center;

                    span {
                        margin: 0 !important;
                    }
                }
            }
        }
        
        .ant-modal-confirm-btns{
            width: 100%;
            text-align: center;
        }
    }

}

.error-modal {
    
    #modal-error-btn{
        background-color: $bg-error-btn !important;
        border-color: $bg-error-btn !important;
        min-width: $btn-min-width;
    }
}

.success-modal {
    
    #modal-success-btn{
        background-color: $bg-success-btn;
        border-color: $bg-success-btn;
        min-width: $btn-min-width;
    }

    #modal-cancel-btn{
        min-width: $btn-min-width;
    }
}