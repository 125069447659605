$prime: #1890ff !default;
.merchant-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    margin-bottom: 45px;
    min-height: 65vh;

    a{
        padding-top: 5px;
        color: #FDB415;
    }

    .merchant-header{
        span{
            display: flex;
            justify-content: center;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #0B1339;
        }
    }

    .merchant-subheader{
        font-size: 15px;
        color: #576793;
        padding-bottom: 25px;
        a{
            color: #FDB415;
            padding-left: 5px;
        }
    }

    .merchant-subfooter{
        font-size: 15px;
        color: #0B1339;
        a{
            color: #FDB415;
            padding-left: 5px;
        }
    }

    .facebook_btn{
        margin-top: 20px;
        background-color: #FFFFFF;
        color: #0B1339;
        font-weight: 00;
        font-size: 15px;
        border-radius: 5px;
        width: 324px;
        height: 50px;
        border: 1px solid #0B1339;
        i{
            color: #4267B2;
        }
        img{
            padding-right: 5px;
        }
    }

    .merchantHr{
       display: flex;
       div{
        width: 170px;
        height: 13px;
        border-bottom: 1.5px solid #C4C4C4;
       }
       span{
           padding: 0 5px;
       }
    }

    form {
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        
        
        .hr{
            border-bottom: 1.5px solid #C4C4C4;
            margin: 25px -25px;
        }

        .form-input{
            margin-top: 10px;
        }
        
        .ant-form-item{
            margin: 7px 0;
        }
        input{
            // padding: 10px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
            color: #576793;
        }

        span.ant-input-affix-wrapper{
            padding: 0px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
            overflow: hidden;

            input{
                padding-left: 10px;
            }

            span.ant-input-suffix{
                margin-right: 8px;
                margin-left: 8px;
            }
        }

        .select_country{
            .ant-select-selector{
                padding: 10px;
                width: 318px;
                height: 50px;
                background: #FFFFFF;
                border: 1px solid #FDB415;
                box-sizing: border-box;
                border-radius: 5px;
                color: #576793;
            }
            .ant-select-arrow{
                color: #FDB415;
            }
        }

        .select_tags{
            .ant-select-selector{
                width: 318px;
                height: 50px;
                padding-left: 10px;
                background: #FFFFFF;
                border: 1px solid #FDB415;
                box-sizing: border-box;
                border-radius: 5px;
                color: #576793;
            }
            .ant-select-arrow{
                color: #FDB415;
            }
        }

        textarea{
            padding: 10px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #FDB415;
            box-sizing: border-box;
            border-radius: 5px;
            color: #576793;
        }

        .input_black{
            padding: 10px;
            width: 324px;
            height: 50px;
            background: #FFFFFF;
            border: 1px solid #0B1339;
            box-sizing: border-box;
            border-radius: 5px;
            color: #576793;
        }

        .select_input{
            div{
                padding: 10px !important;
                width: 324px !important;
                height: 50px !important;
                background: #FFFFFF;
                border: 1px solid #0B1339 !important;
                box-sizing: border-box !important;
                border-radius: 5px !important;
                color: #576793;
            }
            .ant-select-arrow{
                color: #FDB415;
            }
        }

        .error-input{
            color: red

        }

        .submit_btn{
            margin-top: 5px;
            width: 324px;
            height: 50px;
            background-color: #FDB415;
            border-color: #FDB415;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #FFFFFF;
        }

        .form-row{
            display: flex;
            margin-top: 10px;
            
            flex-direction: row;
            div{
                padding: 0 4px;
                .form-input{
                    margin-top: 10px;
                }
                
                .ant-form-item{
                    margin: 12px 0;
                }
            }
        }
        .error { 
            border: 1px solid red; 
        }
    }

    .row{
        padding: 0 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width : 480px) {

        form{
            padding: 0 5px;

            input{
                width: 287px;
            }
        }

        .merchantHr{
            display: flex;
            div{
             width: 138px;
            }
         }
        .facebook_btn{
            width: 287px;
        }
    }
}