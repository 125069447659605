.header_menu{
    width: 300px;
}

.header{
    display: flex;
    background: #0B1339;
    color: #ffffff;
    position: relative;
    justify-content: space-between;
    height: 75px;
    
    @at-root #{&}__left{
        display: flex;
        .header_tab{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
            font-size: 14px;
            a{
                color: white;
            }
        }
        .header__logo{
            a{
                justify-content: center;
                display: flex;
            }
            img{
                width: 15vh;
                height: 4vh;
            }
        }

        @media (max-width : 550px) {
            .extra{
                display: none;
            }
        }
    }

    @at-root #{&}__logo{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 18px;
    }

   
    @at-root #{&}__right{
        display: flex;

        .user_tab{
            
         font-size: 16px;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;
         padding: 0 18px;

         a{
             color: white;
         }
            

        }
        .header_tab{
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 0 18px;
           
            a{
                color: white;
                margin: 0 6px;
                
            }   

            span{
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px dashed #FDB415;
                border-radius: 34px;
                width: 300px;
                height: 28px;
            }

            .anticon svg {
                width: 15px !important;
                height: 15px !important;
            }
            .header_button{
                background-color: #FDB415;
                color: #0B1339;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                width: 93.5px;
                border-radius: 5px;
                a{
                    color: #0B1339;
                }

            }
            @media only screen and (max-width : 480px) {
                .extra{
                    display: none;
                }
            }
        }

        @media (max-width: 550px) {
            .header_tab{
                span{
                    max-width: 200px;
                    width: unset;
                    height: 46px;
                    text-align: center;
                    padding: 0 5px;
                }
            }
            .extra{
                display: none;
            }
            .user_tab{
                padding-left: 18px;
                padding-right: unset ;
            }
        }
        
    }

    @at-root #{&}__center{
        display: flex;  
    }
}

.menu-title{
    padding: 0 15px;
}

.header-icon{
    svg{
        width: 12px !important;
        height: 12px !important;
    }
}
