
.order{
    .order_icon{
        color: #FDB415;
    }
    .order_info{
        font-size: 14px;
        padding: 5px 0;
        height: 30px;
    }
    .order_info_date_blue{
        font-size: 14px;
        padding: 5px 0;
        height: 30px;
        background-color: #0A1339;
        color: #ffffff;
        margin-bottom: 15px;
        margin-top: 15px;
        padding-left: 10px;
    
    }
    .order_info_date_red{
        font-size: 14px;
        padding: 5px 0;
        height: 30px;
        background-color: #BF0A30;
        color: #ffffff;
        margin-bottom: 15px;
        margin-top: 15px;
        padding-left: 10px;
    }
    .order_quantity_text{
        font-weight: 900;
        font-size: 18px;
        margin-bottom: -2px !important;
    }
    .info_note{
        font-size: 14px;
        padding: 5px 0;
    }
    .info_label{
        font-weight: bold;
        padding-right: 4px;
    }
    .info_label_white{
        font-weight: bold;
        color: #ffffff;
    }
    .order_item{
        .order_data{
            display: flex;
            justify-content: space-between;
            &.nested-option {
                padding: 0 0 0 23px;
            }
            span{
                padding: 2px 0;

                &.text-bold {
                    font-weight: bold;
                }
            }
            .total{
                font-weight: bold;
                font-size: 16px;
            }
        }
    }
    .order_divider{
        margin: 10px 0;
    }

    .custom_divider{
        margin: 6px 0;
    }

    .order_preparation{
        padding: 10px;
        background-color: #FDB415;
        border-radius: 5px;
        margin: 10px 0;
        text-align: center;
    }

}
.order_status{
    display: flex;
    div{
        font-weight: bold;
        flex-direction: row;
    }
}
.status_label{
    font-weight: bold;
    display: flex !important;
    justify-content: flex-end !important;
    padding: 5px;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}

.padding_right{
    padding-right: 17px;
}

.total_amount{
    padding-right: 16px;
}
.ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: -ms-inline-flexbox;
    display: inline-flex;
    .ant-input-affix-wrapper > input.ant-input {
        padding: 0;
        border: none;
        outline: none;
    }
    .ant-input {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;

        font-variant: tabular-nums;
        list-style: none;
        -webkit-font-feature-settings: 'tnum', "tnum";
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 0px solid #d9d9d9;
        border-radius: 2px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

}
.align_right{
    text-align: right !important;
}

.total_amount_right{
    text-align: end !important;
    padding-right: 14vh !important;
}

@media (max-width: 900px) {
    .order_detail_view{
        overflow-x: unset !important;
    }
    .order{
        min-width: 80vh;
    }
    .order_modal{
        min-width: unset;
    }
}

@media (max-width: 550px) {
    .order{
        min-width: 92vh;
    }
    .order_modal{
        min-width: unset;
    }
}

