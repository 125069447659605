$bg-color: #ffffff !default;
.sidebar{
   


    .sideToggle{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 1;
        pointer-events: none;
    }

    .toggle-icon{
        height: 75px;
        cursor: pointer;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;

        div{
            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                .header__logo{
                    display: flex;
                    a{
                        display: flex;
                        justify-content: center;
                    }
                    img{
                        width: 15vh;
                        height: 4vh;
                        border-radius: 3px;
                    }
                    div{
                        font-size: 11gipx;
                    }
                }
                
            }
        }
        svg{
            height: 22px;
            width: 22px
        }
        // span{
        //     padding-left: 10px;
        // }

  

        
    }

    .toggle-open-icon{
        cursor: pointer;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        width: 200px;
        height: 50px;
        // position: fixed;

        svg{
            height: 16px;
            width: 16px
        }
        .menu-title{
            padding-left: 10px;
        }
    }

    .toggle-close-icon{
        cursor: pointer;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 10px;
        width: 80px;
        height: 50px;

     
        svg{
            height: 16px;
            width: 16px
        }
        span{
            padding-left: 10px;
        }
    }
    
    
}
.ant-layout-sider{
    background: #0B1339 !important;
    height: 100vh;
    // position: fixed !important;
    // z-index: 5;

    ul{
        background: #0B1339;
        color:#ffffff;
    }

    .ant-menu-item-selected {
        color: #fdb415;
        border-right: 3px solid #fdb415;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: unset;
    }

}

.ant-layout{
    background-color: #ffffff !important;
}

.ant-menu-inline{
    border-right : unset !important;
}



